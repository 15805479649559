/**
 *--------------------------------------------------------------------------
 * Support - Tab
 *--------------------------------------------------------------------------
 *
 * Style from tab component.
 * Add animation for display tab component.
 * Remove display tab component with animation.
 */
.tab {
  &:not(.show) {
    @apply hidden;
  }

  &.show {
    animation: 0.7s fadeIn forwards;
  }
}
