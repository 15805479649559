html,
body {
  @apply scroll-smooth;
}

.container {
  @apply mx-auto;
  @screen xl {
    @apply px-20;
  }
}

.h-single {
  @screen lg {
    height: calc(100vh - 200px);
  }
}

.h-container {
  @screen lg {
    min-height: calc(100vh - 295px);
  }
}

.slick-slide {
  @apply z-10 #{!important};
}

.slick-list {
  @apply p-0 #{!important};
}

#menu {
  @apply opacity-0;

  &:not(.active) {
    @apply hidden;
  }

  &.active {
    @apply block;
    animation: fadeIn .3s forwards;
  }
}

.auth-container-mobile {
  min-height: calc(100vh - 240px);
}

.about-image {
  @apply w-8/12;
  @screen sm {
    @apply w-3/5;
  }
  @screen lg {
    width: 526px;
  }
  @screen xl {
    width: 746px;
  }
}

.about-svg {
  @apply w-2/12;
  @screen sm {
    @apply w-1/5;
  }
  @screen lg {
    width: calc(50% - 263px);
  }
  @screen xl {
    width: calc(50% - 373px);
  }
}

.about-svg-contact  {
  @apply w-2/12;
  @screen sm {
    @apply w-1/5;
  }
  @screen lg {
    width: calc(50% - 170px);
  }
  @screen xl {
    @apply w-5/12;
  }
}

.account-input-user,
.account-input-package {
  &:not(.active) {
    @apply p-0 border-none disabled pointer-events-none;
  }

  &.active {
    @apply py-2 px-6 border border-gray-400 rounded-full;
  }
}

.text-disabled {
  @apply text-gray-300;
}

.legals-single {
  p {
    @apply mb-4;
  }
}

input:not([type="radio"]),
input:not([type="checkbox"]),
input:not([type="submit"]),
textarea,
select {
  @apply bg-input py-3;
}

textarea {
  resize: none;
}

.card-locations{
  border: 2px solid #fff;
}

.map-items {
  max-height: 235px;
  @screen sm {
    max-height: 150px;
  }
  @screen md {
    max-height: 405px;
  }
}

.card-body{
  background: #fff;
  width: 100%;
  vertical-align: top;
}

.card-content{
  text-align: left;
  color: #333;
  padding: 15px;
}

.card-text{
  font-size: 14px;
  font-weight: 300;
}

.content-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

textarea {
  resize: none;
}

.single {
  @apply font-normal text-sm;
}
