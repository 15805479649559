.modal {
  @apply fixed bottom-0 left-0 right-0 top-0 items-center justify-center inset-0 z-40 w-full h-full px-4 overflow-x-hidden overflow-y-auto max-h-full;
}

.modal-content {
  @apply w-full max-h-full z-1;
  @screen sm {
    @apply relative;
  }

  > div {
    @apply rounded-lg bg-white px-2 py-8;
    @screen sm {
      @apply relative px-6 my-8;
    }
    @screen lg {
      @apply px-8;
    }
  }
}

.modal-xs {
  @screen sm {
    @apply max-w-xs;
  }
}

.modal-md {
  @screen sm {
    @apply max-w-md;
  }
}

.modal-lg {
  @screen sm {
    @apply max-w-lg;
  }
}

.modal-xl {
  @screen sm {
    @apply max-w-xl;
  }
}

.modal-2xl {
  @screen sm {
    @apply max-w-2xl;
  }
}

.modal-backdrop {
  @apply fixed bottom-0 left-0 right-0 top-0 z-0 inset-0 bg-white;
  @screen sm {
    @apply bg-gray-600 opacity-90;
  }
}

.modal-close-container {
  &:not(.close-absolute) {
    @apply absolute top-0 right-0 mr-1 mt-2 z-10;
    @screen sm {
      @apply relative mt-0 mr-0;
    }
  }

  .modal-close {
    @apply p-2 rounded outline-none duration-300 ease-in-out text-gray-500;
    @screen sm {
      @apply p-0;
    }

    &:hover {
      @apply text-gray-700;
    }
  }
}
