/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;

/**
 * Use this directive to control where Tailwind injects the responsive
 * variations of each utility.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind screens;

/**
 * Styles for support plugins.
 */
@import "~slick-carousel/slick/slick.scss";
@import "~jssocials/styles/jssocials";
@import "~jssocials/styles/jssocials-theme-flat";

/**
 * Styles for support components.
 */
@import "support/collapse";
@import "support/modals";
@import "support/tab";

/**
 * Custom especific styles.
 */
@import "animations";
@import "buttons";
@import "collapse";
@import "containers";
@import "links";
@import "cards";
@import "modals";
@import "sliders";
