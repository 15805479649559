/**
 *--------------------------------------------------------------------------
 * Support - Collapse
 *--------------------------------------------------------------------------
 *
 * Style from collpase component.
 * Add animation for display collapse component.
 */
.collapse {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transform-origin: top;
  transform: scaleY(0);
  visibility: initial;

  &.in {
    animation: 1s fadeIn forwards;
    height: 100%;
    transform-origin: top;
    transform: scaleY(1);
  }
}
