.slick-initialized {
  @apply opacity-100 visible;
}

.slick-dots {
  @apply absolute bottom-0 right-0 left-0 flex justify-center w-full mr-6 mb-5;
  @screen sm {
    @apply mb-7;
  }
  @screen xl {
    @apply mb-10;
  }

  li {
    @apply px-3;
  }

  button {
    font-size: 0;
    @apply bg-gray-400 block h-4 w-4 rounded-full outline-none opacity-50;
  }

  .slick-active button {
    @apply opacity-100 bg-white;
  }
}

.slick-arrow {
  @apply absolute text-[0px];

  &::before {
    content: "";
    @apply inline-block w-[70px] h-[70px] transform scale-100 duration-300;
  }

  &:hover::before {
    @apply transform scale-110 duration-300;
  }

  &.slick-prev::before {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.4" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg>');
  }

  &.slick-next::before {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.4" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>');
  }
}

.slider-home {
  .slick-arrow {
    top: 115px;
  }

  .slick-prev {
    left: -70px;
  }

  .slick-next {
    right: -70px;
  }
}

.slider-single-for {
  .slick-arrow {
    @apply top-1/2 -translate-y-1/2 z-1;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}

.slider-single-nav {
  .slick-slide {
    @apply mx-1;

    @screen sm {
      @apply mx-2;
    }
  }

  .slick-list .slick-slide:not(.slick-current) {
    @apply opacity-60;
  }
}
