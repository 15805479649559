.card {
  text-align: center;

  margin-top: 110px;
  @screen sm {
    margin-top: 112px;
  }
}

.card-platform {
  backface-visibility: hidden;
  height: calc(100% - 75px);
  margin-top: 75px;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover,
  &:focus {
    text-decoration: none;
    transform: scale(1.008);
  }

  &__header {
    height: 100px;
    position: relative;

    &::after {
      top: -40%;
      width: 95%;
    }

    &--logo-center::after {
      top: -8%;
      width: 75%;
    }

    &--logo-double {
      &::before {
        background-image: url(/images/logos/ideasracing.svg);
        top: 20%;
        width: 75%;
      }
    }
  }

  &__body {
    padding: 1.5rem 1.25rem 2rem;
  }

  &__excerpt {
    margin: auto;
    width: 90%;
  }
}

.card-services {

  &__header {
    background: center/auto 55% no-repeat;
    height: 125px;
    @each $service in 'data', 'marketing', 'analytics' {

      &--#{$service} {
        background-image: url(/images/icons/cards/#{$service}.png);
      }
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
}
