/**
 *--------------------------------------------------------------------------
 * Support - Modal
 *--------------------------------------------------------------------------
 *
 * Style from modal component.
 * Add animation for display modal component.
 * Remove display modal component with animation.
 */
.modal {
  display: none;

  &.show {
    animation: 0.7s fadeIn forwards;
    display: flex;
  }
}

.modal.fadeOut {
  animation: 0.7s fadeOut forwards;
}
