a,
button {
  @apply break-words duration-300 ease-in-out outline-none #{!important};
}

.stretched-link {
  &:focus {
    @apply outline-none;
  }

  &::after {
    @apply absolute top-0 right-0 bottom-0 left-0 pointer-events-auto;
    content: "";
  }
}


.link-hash-desktop {
  @apply relative;

  &::before {
    @apply border-b-2 border-transparent hover:border-orange-100 absolute bottom-0 mb-7 left-1/2 -translate-x-1/2;
    content: "";
    width: calc(100% - 1rem);
  }

  &.active::before {
    @apply border-orange-100;
  }
}

.link-hash-mobile.active {
  @apply bg-black text-white;
}

.menu-dropdown-show.active {
  @apply text-orange-100;
}

.menu-dropdown {
  &:not(.active) {
    @apply hidden;
  }

  &.active {
    @apply flex;
  }
}

.check-venue-add {
  &:not(:checked) + label {
    @apply hover:shadow-md hover:border-gray-100 text-gray-400;
  }

  & + label {
    @apply cursor-pointer duration-300 ease-in-out;
  }

  &:checked + label {
    @apply border-purple-800 text-purple-800;
  }
}

.check-venue-modify {
  @apply cursor-pointer duration-300 ease-in-out;

  & + div {
    @apply border-transparent;
  }

  &:not(:checked):hover + div {
    @apply shadow-lg border-gray-100;
  }

  &:checked + div {
    @apply border-purple-800;
  }
}

.menu-item.active {
  @apply bg-orange-100 text-black;
}

.jssocials-share {
  @apply m-0;

  .jssocials-share-link {
    height: 50px;
    width: 50px;
    @apply rounded-none;
  }

  .jssocials-share-logo {
    margin-top: 5px;
    @apply mx-auto;
  }
}
