.collapse-container:not(.collapse-footer) {
  a:not([data-toggle="collapse-open"]) svg {
    @apply hidden;
  }

  a[data-toggle="collapse-open"].show svg {
    @apply transform -rotate-180 transition duration-300 ease-in;
  }

  a[data-toggle="collapse-open"].show svg.chevron {
    @apply transform -rotate-180 transition duration-200 ease-in;
  }
}
