@mixin button($bg, $color, $border, $hover-bg, $hover-color, $hover-border) {
  transition: all 0.5s;
  @apply #{$bg} #{$color} border #{$border};

  &:hover {
    transition: all 0.5s;
    @apply #{$hover-bg} #{$hover-color} #{$hover-border};
  }
}

.btn {
  @apply cursor-pointer text-center rounded-none;

  &.disabled {
    @apply pointer-events-none #{!important};
  }

  &:hover {
    @apply no-underline;
  }

  &:focus,
  &:active,
  &:visited {
    @apply shadow-none outline-none;
  }

  &.btn-orange {
    @include button("bg-orange-100", "text-white", "border-orange-100", "bg-orange-100", "text-black", "border-orange-100");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-black {
    @include button("bg-black", "text-white", "border-black", "bg-black", "text-white", "border-black");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-purple {
    @include button("bg-purple-700", "text-white", "border-purple-700", "bg-purple-800", "text-white", "border-purple-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-blue {
    @include button("bg-blue-700", "text-white", "border-blue-700", "bg-blue-800", "text-white", "border-blue-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-red {
    @include button("bg-red-700", "text-white", "border-red-700", "bg-red-800", "text-white", "border-red-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-green {
    @include button("bg-green-700", "text-white", "border-green-700", "bg-green-800", "text-white", "border-green-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-outline-orange {
    @include button("bg-transparent", "text-orange-100", "border-orange-100", "bg-orange-100", "text-white", "border-orange-100");

    &.disabled {
      @apply bg-transparent text-orange-100 border-orange-100;
    }
  }

  &.btn-outline-purple {
    @include button("bg-transparent", "text-purple-700", "border-purple-700", "bg-purple-800", "text-white", "border-purple-800");

    &.disabled {
      @apply bg-transparent text-purple-400 border-purple-300;
    }
  }

  &.btn-outline-gray {
    @include button("bg-transparent", "text-purple-800", "border-gray-400", "bg-transparent", "text-gray-500", "border-gray-400");

    &.disabled {
      @apply bg-transparent text-gray-300 border-gray-200;
    }
  }

  &.btn-outline-black {
    @include button("bg-transparent", "text-black", "border-transparent", "bg-black", "text-white", "border-black");

    &.active {
      @apply bg-black border-black text-white;
    }

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }


  &.btn-outline-white {
    @include button("bg-transparent", "text-white", "border-transparent", "bg-white", "text-black", "border-white");

    &.active {
      @apply bg-white border-white text-black;
    }

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }
}
